import React, { Component } from 'react';



export class Shopcallback extends Component {
    static displayName = Shopcallback.name;
    constructor(props) {
        super(props);
        this.state =
        {
            error: false,
            complete: false,
            gift: false,
        }
    }

    checkOnItems = async () => {
        console.log("DID call checkOnItems");
        let formData = new FormData();
        formData.append('paymentId', this.props.match.params.pid);

        var json = { completed: false };

        while (!this.state.complete && !this.state.error) {
            var response = await fetch("api/payment/CheckOnPayment",
                {
                    body: formData,
                    method: "post",
                    headers: {
                        ApiKey: process.env.REACT_APP_API_KEY
                    }
                });
            if (response.ok) {
                var json = await response.json();
                setTimeout(1000);
                console.log(json.completed);
                this.setState({ complete: json.completed })

                if (json.additionalInfo != null) {
                    var info = json.additionalInfo.toString();
                    if (info.startsWith("buyForUserName_"))
                        this.setState({ gift: true })
                }
            }
            else {
                this.setState({ error: true })
                return;
            }
        }
    }

    componentWillMount = () => {
        console.log("DID mount");
        this.checkOnItems();
    }

    renderContent = () => {
        if (!this.state.error) {
            if (!this.state.complete) {
                //not complete
                if (this.state.gift == true)
                    return <React.Fragment><h1>Your Items are being gifted ...</h1> <p>please wait a few seconds while we process your order!</p></React.Fragment>
                else
                    return <React.Fragment><h1>Your Items are being added to your invertory ...</h1> <p>please wait a few seconds while we process your order!</p></React.Fragment>

            }
            else {
                //Completed
                return <React.Fragment><h1>All purchased items were processed sucessfull!</h1><div className="playNowContainer">
                    <a href="/game">Back to the Hunt</a>
                </div></React.Fragment>
            }
        }
        else {
            //error
            return <React.Fragment><h2>There was an error while processing!</h2>
                <p>Please check your user-profile in game and try to restart the process from there</p>
                <p>If this does not help please contact us at support@ghostgamestudios.de</p>
                <p>Please include following PaymentId: {this.props.match.params.pid} </p></React.Fragment>
        }
    }

    render() {

        return (
            <React.Fragment>

                <img src={require('./../assets/login/Login_screen_top.png')} className="loginTopImageSmall" />
                {this.renderContent()}

            </React.Fragment>




        );
    }

}
