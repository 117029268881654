import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Home } from './components/Home';
import { ForgotPasswordRetrieve } from './components/ForgotPasswordRetrieve';

//import './App.css'
import './AppPages.css'
import Login from './components/Login';
import { Imprint } from './components/Imprint';
import { Layout } from './components/Layout';
import { Welcome } from './components/Welcome';
import { ImprintEN } from './components/ImprintEN';
import { Shopcallback } from './components/Shopcallback';
import { ActivateAccount } from './components/ActivateAccount';

export default class AppPages extends Component {
  static displayName = AppPages.name;

  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/login' component={Login} />
          <Route path='/welcome' component={Welcome} />
          <Route path='/imprint' component={Imprint} />
          <Route path='/imprintEN' component={ImprintEN} />
          <Route path='/shopcallback/:pid' component={Shopcallback} />
          <Route path="/forgotPasswordRetrieve/:id" component={ForgotPasswordRetrieve} />
          <Route path="/activateAccount/:id" component={ActivateAccount} />

        </Switch>
      </Layout>
    );
  }
}
