import React, { Component } from 'react';


export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <React.Fragment>

        {this.props.children}
        <div id="footer"><a href="/imprint">IMPRINT</a></div>
      </React.Fragment>
    );
  }
}
