import React, { Component } from 'react';



export class Imprint extends Component {
  static displayName = Imprint.name;


  render() {

    return (
      <React.Fragment>
        <div className="container">
        <div className="backToGame"><a href="/"><img src={require("./../assets/button_back.png")}></img></a></div>

          <h1>Impressum</h1>

          <p>Informationspflicht laut § 5 TMG.</p>

          <p>Ghostgame Studios UG (haftungsbeschränkt)<br/>
          Augustenstraße 67<br/>
          80333	München<br/>
          info@ghostgamestudios.de</p>




          <h1>DATENSCHUTZERKLÄRUNG</h1>



          <h2>Diese Datenschutzerklärung gilt für die Website ghost-trappers-classic.com einschließlich des über diese Website bereitgestellten Spiels.</h2>


          <h2>Verantwortlicher:</h2>

          <p>Ghostgame Studios UG (haftungsbeschränkt)<br/>
          Augustenstraße 67<br/>
          80333	München<br/>
          info@ghostgamestudios.de</p>


          <h2>Verarbeitung von personenbezogenen Daten</h2>

          <p>Wir verarbeiten im Rahmen der Website personenbezogene Daten von Ihnen unter anderem wie folgt (weitere Datenverarbeitungen im Rahmen der Website entnehmen Sie bitte den nachfolgenden Ziffern dieser Datenschutzerklärung):</p>

          <h3>Logfiles beim Besuch der Website</h3>

          <p>Bei der Nutzung unserer Webseite werden von unserem Hostinganbieter bei jedem Zugriff auf die Server sog. „Logfile“-Daten protokolliert wie bspw. Name der aufgerufenen Website, zuvor besuchte Seite („Referrer“-URL), Produkt- und Versionsinformation des verwendeten Browsers und des Betriebssystems, anfragender Provider, Datum und Uhrzeit des Zugangs, verwendete Suchmaschinen, Land des Zugriffs, übertragene Datenmenge, Namen heruntergeladener Dateien und IP-Adresse.</p>
          <p>Rechtsgrundlage für die Verarbeitung ist Artikel 6 Absatz 1 f) DSGVO. Unser berechtigtes Interesse zur Speicherung der Logfile-Daten liegt in der Gewährleistung der Systemsicherheit einschließlich der Aufklärung von Missbrauch. Die IP-Adressen wird nach höchstens 7 Tagen gelöscht, sofern sie nicht aufgrund eines sicherheitsrelevanten Vorfalls länger benötigt wird, bspw. zur Aufklärung oder zu Beweiszwecken.</p>

          <h3>Kontaktanfragen:</h3>

          <p>Bei Kontaktanfragen verarbeiten wir Ihre personenbezogenen Daten wie Name, Anschrift, E-Mail-Adresse, Telefonnummer o.ä., die wir für die Beantwortung Ihrer Anfrage benötigen.</p>

          <p>Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen von Kontaktanfragen ist Art. 6 Abs. 1 b) Datenschutzgrundverordnung.</p>

          <p>Im Rahmen von Kontaktanfragen speichern wir Ihre personenbezogenen Daten so lange, wie es für die Bearbeitung Ihrer Anfrage erforderlich ist, zuzüglich einer angemessenen Aufbewahrungsfrist für Rückfragen.</p>

          <p>Die Bereitstellung dieser personenbezogenen Daten ist nicht gesetzlich oder vertraglich vorgeschrieben oder für einen Vertragsschluss erforderlich. Wenn Sie uns diese Daten nicht zur Verfügung stellen, können wir allerdings Ihre Kontaktanfrage nicht oder - im Falle eingeschränkter Kontaktangaben – nicht auf allen abgefragten Kommunikationswegen beantworten.
Registrierung/ Bestellungen:</p>

          <p>Bei Ihrer Registrierung oder Bestellungen verarbeiten wir Ihre personenbezogenen Daten wie Name, Anschrift, E-Mail-Adresse, Telefonnummer, Geburtsdatum, selbst gewählter Benutzername, Zahlungsdaten o.ä., die wir für die Erfüllung des Vertragsverhältnisses mit Ihnen oder zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, benötigen.</p>

          <p>Ihre im Rahmen von Registrierung bzw. Bestellungen erhobenen personenbezogene Daten speichern wir jeweils so lange, wie es für die Erfüllung des Vertragsverhältnisses (ggf. einschließlich der Bereitstellung des Kundenkontos) und/oder zur Durchführung auf Ihre Anfrage hin erfolgender vorvertraglicher Maßnahmen und/oder im Hinblick auf Gewährleistungs-, Garantie- oder vergleichbare Verpflichtungen und/oder oder im Hinblick auf gesetzliche Aufbewahrungsfristen erforderlich ist.</p>

          <p>Rechtsgrundlagen für die Verarbeitung Ihrer im Rahmen von Registrierung bzw. Bestellungen erhobenen personenbezogenen Daten sind Art. 6 Abs. 1 b) und Art. 6 Abs. 1 c) Datenschutzgrundverordnung.</p>

          <p>Die Bereitstellung dieser personenbezogenen Daten ist nicht gesetzlich oder vertraglich vorgeschrieben. Sie ist aber für den Vertragsabschluss, also die Durchführung der Registrierung bzw. Bestellung erforderlich, soweit die betreffenden Angaben in unserem Registrierungs-/Bestellablauf zwingend (statt nur freiwillig) vorzunehmen sind.</p>

          <h3>Durchführung des Spiels:</h3>

          <p>Am Spiel nehmen Sie unter einem Benutzernamen Teil, den Sie bei der Registrierung wählen.</p>

          <p>Der Benutzername wird nur registrierten anderen Teilnehmern am Spiel und nur für Zwecke der Anzeige des Highscores und gegebenenfalls für Zwecke des Tauschs von Spielgegenständen über einen spielinternen Marktplatz angezeigt.</p>

          <p>Ihre Spieldaten werden auf unseren bzw. von uns angemieteten Servern innerhalb der EU verarbeitet, soweit nachstehend in dieser Datenschutzerklärung nichts anderes angegeben ist.</p>

          <p>Ihre Spielerdaten einschließlich Ihres Spielverlaufs speichern wir jeweils in dem Umfang und so lange, wie es für die Durchführung des Spiels, insbesondere auch für Ihren Spielfortschritt und die Verbesserung des Spielerlebnisses, erforderlich ist.</p>

          <p>Wenn Sie sich vom Spiel abmelden oder ihr Spielerkonto aus anderen Gründen gelöscht wird, löschen wir Ihre Spielerdaten.</p>

          <p>Soweit die Datenverarbeitung für die oben beschriebenen Zwecke mit Ihrer Einwilligung erfolgt, ist Rechtsgrundlage Artikel 6 Absatz 1 a) DSGVO (Einwilligung). Im Übrigen erfolgt die Datenverarbeitung auf Grundlage von Artikel 6 Absatz 1 b) DSGVO („Vertragserfüllung“) oder Art. 6 f) DSGVO („berechtigte Interessen“), wobei die berechtigten Interessen in den oben genannten Zwecken liegen.</p>

          <h3>Newsletter:</h3>
          <p>Wenn Sie sich für unseren Newsletter anmelden, verarbeiten wir die dabei erhobenen Daten wie Ihre E-Mail-Adresse, Anrede etc. zu Zwecken des Versands des Newsletters.</p>

            <p>Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen der Zusendung unserer Newsletters ist Art. 6 Abs. 1 a) Datenschutzgrundverordnung.</p>
          <p>Wir speichern die personenbezogenen Daten, die wir für die Versendung des Newsletters benötigen, so lange, bis Sie Ihre Einwilligung in den Erhalt des Newsletters widerrufen.</p>
            
          <p>Soweit Angaben im Rahmen der Newsletter-Anmeldung zwingend (statt nur freiwillig) vorzunehmen sind, können wir Ihre Newsletter-Anmeldung ohne diese Angaben nicht entgegennehmen.</p>
              

<h2>Verwendung von Cookies</h2>

<p>In diesem Abschnitt informieren wir Sie über den Einsatz von Cookies auf unserer Website.</p>
            
<h3>Beschreibung und Funktionsweise</h3>

<p>Cookies sind kleine Textdateien, die auf dem Computer des Nutzers gespeichert werden und die eine Analyse der Benutzung der Website durch den Nutzer ermöglichen.</p>
            
<h3>Eigene Cookies</h3>
              
<p>Wir setzen Cookies ein, um die Nutzung der Website für den Besucher einfacher und bequemer zu gestalten bzw. bestimmte Funktionen überhaupt erst zu ermöglichen. </p>
            
<p>Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten durch uns im Zusammenhang mit der Verwendung von Cookies ist Art. 6 Abs. 1 f) Datenschutzgrundverordnung („berechtigtes Interesse“). Das berechtigte Interesse ergibt sich aus den oben genannten Zwecken.</p>
              
<p>Im Rahmen der Nutzung von Cookies speichern wir Ihre personenbezogenen Daten so lange, wie es nötig ist, um die Nutzung unserer Webseite einfacher und bequemer zu gestalten.</p>
              
<h3>Cookies Dritter</h3>
            
<p>Auf der Website können auch Cookie von Drittanbietern verwendet werden, um Informationen von unserer Webseite und anderen Stellen im Internet zu erfassen oder zu erhalten und diese Informationen dann zu nutzen, um bspw. Webtracking-Dienste, Bewertungsdienste oder auf Zielgruppen ausgerichtete Werbeanzeigen anzubieten. </p>
            
<p>Im Rahmen der Nutzung von Cookies werden Ihre personenbezogenen Daten so lange gespeichert, wie es nötig ist, um die oben beschriebenen Zwecke zu erreichen.</p>
              
<p>Soweit die Datenverarbeitung für die oben beschriebenen Zwecke mit Ihrer Einwilligung erfolgt, ist Rechtsgrundlage Artikel 6 Absatz 1 a) DSGVO (Einwilligung). Im Übrigen erfolgt die Datenverarbeitung auf Grundlage von Artikel 6 Absatz 1 f) DSGVO („berechtigte Interessen“), wobei die berechtigten Interessen in den oben genannten Zwecken liegen.</p>
            <h3>Widerruf/ Widerspruch/ Einstellungen</h3>

<p>Sie haben jederzeit die Möglichkeit, eine erteilte Einwilligung in das Setzen von Cookies zu widerrufen oder der Datenverarbeitung durch Cookies zu widersprechen, indem Sie die Cookies in den Einstellungen Ihres Browsers löschen.</p>

<p>Auch können Sie Ihren Browser so einstellen, dass eine Speicherung von Cookies nur akzeptiert wird, wenn Sie dem zustimmen.</p>

<p>Eine weitere Möglichkeit zu Widerruf, Widerspruch und/oder Verwaltung Ihrer Cookies finden Sie hier:</p>

<p>Was Anzeigen-Cookies anbelangt, so können Sie viele davon über folgende Dienste blockieren und/oder verwalten:</p>
<ul>
            <li>www.aboutads.info/choices/</li>
            <li>www.youronlinechoices.com/uk/your-ad-choices/</li>
            <li>www.networkadvertising.org/managing/opt_out.asp</li>
</ul>
            <p>Wenn Sie jedoch Cookies ablehnen, können Sie bestimmte Website-Funktionen, Services, Anwendungen oder Tools möglicherweise nicht nutzen.</p>

<h2>Unity</h2>
                     
          <p>Im Rahmen unseres Spiels setzen wir ein Analyse-Tool von Unity ein. Unity ist eine Anwendung der Unity Technologies ApS, Niels Hemmingsens Gade 24, 1153 Copenhagen, Denmark. Soweit Daten an Unity-Locations außerhalb der EU übermittelt, die kein angemessenes Datenschutzniveau im Sinne des EU-Datenschutzrechts aufweisen, wie bspw. die USA, erfolgt dies auf Grundlage von EU-Standardvertragsklauseln.</p>

<p>Unity verarbeitet Daten u.a. über Ihr Endgerät, Ihren Browser und über Ihren Spielverlauf, anhand derer wir das Spiel an Ihr Endgerät anpassen können und das Spielerlebnis verbessern können.</p>
          
<p>Soweit die Datenverarbeitung für die oben beschriebenen Zwecke mit Ihrer Einwilligung erfolgt, ist Rechtsgrundlage Artikel 6 Absatz 1 a) DSGVO (Einwilligung). Im Übrigen erfolgt die Datenverarbeitung auf Grundlage von Artikel 6 Absatz 1 b) DSGVO („Vertragserfüllung“) oder Art. 6 f) DSGVO („berechtigte Interessen“), wobei die berechtigten Interessen in den oben genannten Zwecken liegen.</p>
              
<p>Einzelheiten zur Datenverarbeitung durch Unity können Sie deren Datenschutzerklärung unter folgendem Link entnehmen:</p>
<ul>

              <li>https://unity3d.com/legal/privacy-policy </li>
            </ul>



        <h2>Google Analytics</h2>
        <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Die erhobenen Daten werden an die Server des Anbieters in den USA übermittelt. Diese Datenübermittlung stützt Google auf die EU-Standardvertragsklauseln.
          Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Soweit Cookies auf der Website nicht ohnehin lediglich bei Erteilung einer entsprechenden Einwilligung gesetzt werden, können Sie die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.</p>

          <p>Die im Rahmen der Verwendung von Google Aalytics verarbeiteten Daten werden nach  26 Monaten automatisch gelöscht.</p>

<p>Soweit die Datenverarbeitung für die oben beschriebenen Zwecke mit Ihrer Einwilligung erfolgt, ist Rechtsgrundlage Artikel 6 Absatz 1 a) DSGVO (Einwilligung). Im Übrigen erfolgt die Datenverarbeitung auf Grundlage von Artikel 6 Absatz 1 f) DSGVO („berechtigte Interessen“), wobei die berechtigten Interessen in den oben genannten Zwecken liegen.
Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:</p>
              <ul>
               	<li>https://tools.google.com/dlpage/gaoptout?hl=de</li>
                 </ul>
          <h2>Betroffenenrechte</h2>

<p>Sie haben gemäß Art. 15 Datenschutzgrundverordnung das Recht Auskunft über die Verarbeitung Ihrer personenbezogenen Daten zu verlangen ("Auskunftsrecht der betroffenen Person").</p>

<p>Sie haben gemäß Art. 16 Datenschutzgrundverordnung das Recht, die Berichtigung und Löschung Sie betreffender unrichtiger personenbezogener Daten zu verlangen ("Recht auf Berichtigung").</p>

<p>Gemäß Art. 17 Datenschutzgrundverordnung können Sie die Löschung Sie betreffender personenbezogener Daten verlangen, sofern einer der dort aufgeführten Gründe zutrifft ("Recht auf Vergessenwerden").</p>

<p>Ebenso haben Sie gemäß Art. 18 Datenschutzgrundverordnung das Recht, die Einschränkung der Verarbeitung Sie betreffender personenbezogener Daten zu verlangen, wenn eine der dort aufgeführten Voraussetzungen zutrifft ("Recht auf Einschränkung der Verarbeitung").</p>

<p>Sie haben gemäß Art. 20 Datenschutzgrundverordnung das Recht, Sie betreffende personenbezogene Daten zur Verfügung gestellt zu bekommen und diese Daten einem anderen Verantwortlichen übermitteln zu lassen ("Recht auf Datenübertragbarkeit").</p>

<p>Widerruf von Einwilligungen: S. Abschnitt „Widerrufsrecht“ in dieser Datenschutzerklärung. </p>

<p>Widerspruchsrecht: Siehe Abschnitt „Widerspruchsrecht“ in dieser Datenschutzerklärung. </p>

<p>Sie haben das Recht sich bei der zuständigen Aufsichtsbehörde zu beschweren. Die zuständige Aufsichtsbehörde ist Bayerische Landesbeauftragte für den Datenschutz (BayLfD), Prof. Dr. Thomas Petri, Postanschrift: Postfach 22 12 19, 80502 München, Hausanschrift: Wagmüllerstraße 18, 80538 München</p>

<h2>Widerrufsrecht</h2>

<p>Ihre ggf. erteilte Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten können Sie jederzeit widerrufen, bspw. per E-Mail an unsere eingangs genannte E-Mail-Adresse. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.</p>


<h2>Widerspruchsrecht</h2>

<p>Soweit unsere Datenverarbeitung auf Artikel 6 Absatz 1 f) DSGVO beruht („berechtigte Interessen“), haben Sie nach näherer Maßgabe des Artikel 21 DSGVO das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen. </p>


<h2>Weitergabe Ihrer Daten</h2>

<p>Soweit nicht bereits an anderer Stelle in dieser Datenschutzerklärung aufgeführt, geben wir Ihre personenbezogenen Daten an folgende weiteren Empfänger beziehungsweise Kategorien von Empfängern weiter:</p>
<ul>
<li>Zahlungsdienstleister</li>
<li>Marketingdienstleister</li>
</ul>
<p>Soweit nicht vorstehend bereits aufgeführt, haben wir die Absicht Ihre personenbezogenen Daten an folgendes Drittland beziehungsweise folgende internationale Organisation zu übermitteln.</p>
<ul>
  <li>Google Ads/Analytics (USA)</li>
<li>Unity Analytics (Denmark / USA)</li>
</ul>
        </div>
      </React.Fragment>




    );
  }

}
