import React, { Component } from 'react';



export class ImprintEN extends Component {
  static displayName = ImprintEN.name;


  render() {

    return (
      <React.Fragment>
        <div className="container">

          <div className="backToGame"><a href="/"><img src={require("./../assets/button_back.png")}></img></a></div>
          <h1>Impressum</h1>

          <p>Informationspflicht laut § 5 TMG.</p>

          <p>Ghostgame Studios UG (haftungsbeschränkt)<br />
Augustenstraße 67<br />
80333	München<br />
info@ghostgamestudios.de</p>


          <h1>PRIVACY POLICY</h1>



          <h2>This data privacy statement applies to the website www.ghost-trappers-classic.de including the game made available through such aforesaid website.</h2>


          <p>Data Controller:</p>

          <p>Ghostgame Studios UG (haftungsbeschränkt)<br />
Augustenstraße 67<br />
80333	München<br />
info@ghostgamestudios.de</p>


          <h2>Processing of Personal Data</h2>

          <p>In the context of our website, we process your personal data in, among others, the following ways (for other processing of personal data collected on our website, please refer to the following clauses of this privacy policy):</p>

          <h3>Logfiles when you visit our website</h3>

          <p>When visiting our website, our host provider protocols, for every access to its servers, so-called “logfile” data such as the name of the retrieved website, the previously visited website (“referrer” URL), product and version information of the used browser and operating system, the retrieving provider, date and time of access, used search engines, country of access, transferred data volume, name of downloaded files, and the IP address. </p>

          <p>Legal basis for the respective processing of data is Article 6 (1) f) General Data Protection Regulation (GDPR). Our legitimate interest in the storage of logfile data is based on the provision of system security including the clarification of cases of data abuse. The IP address will be deleted within max. 7 days unless it is required for a longer period of time due to a security related event, e.g. for purposes of clarification or proof.</p>

          <h3>Contact Enquiries:</h3>

          <p>When you send us a contact enquiry, we process the personal data that we need to respond to your enquiry, such as your name, postal address, email address, phone number etc.</p>

          <p>The legal basis for processing your personal data in connection with contact enquiries is Article 6 (1) b) General Data Protection Regulation (GDPR).</p>

          <p>In conjunction with contact requests, we store your personal data for as long as is necessary to process your request, including an appropriate retention period for follow-up questions.</p>

          <p>The provision of this personal data is not prescribed by law or contract, and it is not necessary for the purpose of entering into a contract. However, if you decide not to disclose this data to us, we will not be able to respond to your contact enquiry or – if you have provided only limited contact details – we will not be able to respond to it via all available communication channels. 
Registrations/ Orders</p>
          
<p>When you register or place an order with us we process the personal data that we need to perform a contract with you, or to take steps at your request prior to entering into the contract, such as name, address, e-mail address, phone number, date of birth, chosen user name and payment details.</p>
            
<p>Personal data which is collected during the registration or order process is stored for as long as it takes to perform the contract (where applicable including the provision of a customer account), and/or to take steps at your request prior to entering into the contract, and/or to meet warranty, guarantee or similar obligations, and/or to comply with statutory archiving requirements.</p>
            
<p>The legal bases for processing the personal data collected in connection with registrations or orders are Article 6 (1) b) and Article 6 (1) c) of the General Data Protection Regulation.</p>
            
<p>The provision of this personal data is not prescribed by law or contract. However, it is necessary to enter into the contract, i.e. to complete the registration or order, if the data is mandatory (as opposed to voluntarily) in the registration/ order process. </p>
            
<h3>When playing the game:</h3>
            
<p>You participate in the game under a user name which you choose during registration.</p>
            
          <p>The user name will only be displayed to registered other participants in the game and only for the purpose of displaying the high score and, if applicable, for the purpose of exchanging game items via a game-internal marketplace.</p>
            
<p>Your game data will be processed on our servers or servers rented by us within the EU, unless otherwise stated below in this Privacy Policy.  </p>
            
<p>We store your player data, including your game history, in each case to the extent and for as long as it is necessary for the performance of the game, including in particular for your game progress and the improvement of the game experience.  </p>
            
<p>If you log out of the game or your player account is deleted for other reasons, we will delete your player data.</p>
            
<p>Insofar as data processing for the purposes described above is carried out with your consent, the legal basis is Article 6 (1) a) DSGVO (consent). Otherwise, data processing is based on Article 6(1)(b) DSGVO ("performance of contract") or Article 6(f) DSGVO ("legitimate interests"), whereby the legitimate interests lie in the above-mentioned purposes.</p>
            
<h3>Newsletter:</h3>
            <p>When you subscribe to our newsletter, we process data to enable us to send the newsletter to you, such as your email address and name. </p>

          <p>The legal basis for processing your personal data in connection with newsletter subscriptions is Article 6 (1) a) General Data Protection egulation.</p>
<p>We store the personal data necessary to send you the newsletter until you revoke your consent to receiving the newsletter by unsubscribing.</p>
                 
<p>If you do not provide us with data reqested as mandatory (as opposed to voluntary) for newsletter subscription, we will reject your subscription request.</p>
              

          <h2>Use of Cookies</h2>
            
          <p>This section informs you about how we use cookies on our website.</p>
          
              <h3>Description and Functionality</h3>
          
<p>Cookies are small text files which are saved on your computer and enable an analysis of your use of the website.</p>
          
<h3>Own Cookies</h3>
          
<p>We use cookies on our website to optimise the user experience and to provide certain functions. </p>
          
<p>The legal basis for the processing of personal data by us in connection with the use of cookies is Article 6 (1) f) of the General Data Protection Regulation (”legitimate interest”). The legitimate interest ensues from the above-mentioned purposes.</p>
          
<p>When cookies are used, we store your personal data for as long as necessary to optimise your user experiece on the website.</p>
          
<h3>Third Party Cookies</h3>
          
<p>Third party cookies may also be used on the website to collect information about our website and other sites on the internet. This information is then used for services such as web tracking, analyses or target audience-specific advertising. </p>
          
<p>Personal data is stored in conjunction with the use of cookies for as long as is necessary for the purposes described above.</p>
          
<p>If and to the extent that the processing of data for the aforesaid purposes is made with your consent, the legal basis is Article 6 1 a) of the General Data Protection Regulation (Consent). Otherwise the data is processed on the legal basis of Artikel 6 1 f)of the General Data Probtection Regulation (“legitimate interests”), with the legitimate interests in question being the purposes mentioned above.</p>
   <h3>Revocation/ Objection/ Settings</h3>

          <p>You can revoke your consent to accept cookies or for data to be processed by cookies at any time by changing your browser’s cookie settings.</p>

          <p>You can also set your browser to only accept cookies if you agree to it.</p>

          <p>You can find other options for opting out from or managing cookies here:</p>

          

          <p>You can manage and/or block many advertisement cookies via the following services:</p>

          <ul>
                <li>www.aboutads.info/choices/</li>
                <li>www.youronlinechoices.com/uk/your-ad-choices/</li>
                <li>www.networkadvertising.org/managing/opt_out.asp</li>
              </ul>
<p>your browser is configured to reject all cookies you may not be able to use some of the website's functions, services, applications or tools.</p>
                <h2>Unity</h2>

                <p>In connection with our game, we use an analytics tool from Unity.  Unity is an application of Unity Technologies ApS, Niels Hemmingsens Gade 24, 1153 Copenhagen, Denmark. Insofar as data is transferred to Unity locations outside the EU that do not have an adequate level of data protection within the meaning of EU data protection law, such as the USA, this is done on the basis of EU standard contractual clauses.</p>
          
          <p>Unity processes data about your terminal device, your browser and your gaming history, among other things, which we can use to adapt the game to your terminal device and improve the gaming experience.</p>

                <p>Insofar as data processing for the purposes described above is carried out with your consent, the legal basis is Article 6 (1) a) DSGVO (consent). Otherwise, data processing is based on Article 6(1)(b) DSGVO ("performance of contract") or Article 6(f) DSGVO ("legitimate interests"), whereby the legitimate interests lie in the above-mentioned purposes.</p>
          
          <p>Details of data processing by Unity can be found in their privacy policy at the following link:</p>

          <ul>
                
<li>https://unity3d.com/legal/privacy-policy </li>
</ul>
          

          
<h2>Google Analytics</h2>
              <p>This Website uses Google Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Google Analytics uses cookies (small text files) which are saved on your computer and enable an analysis of your use of the website. The cookie-generated information about your use of this website is transferred to a Google server in the USA and stored there. The collected information is transferred to a server of the provider in the US. Google bases such aforesaid transfer on the EU Standard Contractual Clauses</p>
<p>In the event of activation of IP anonymisation on this website your IP address will be shortened by Google before such transmission within member states of the European Union or other countries adhering to the treaty on the European Economic Area. The full IP address will only be transmitted to a Google server in the USA and truncated there in specific, exceptional cases. At the request of the operator of this website, Google will use this information to evaluate the way you use this website, to compile reports on website activity, and to provide additional services connected to website use and Internet use to the website operator. The IP-address transferred by your browser under Google Analytics will not be brought together with other data of Google. Where cookies on the website do not require your active consent anyway, you can set your browser to prevent it from saving cookies, but we would like to point out that if you do this, you may not be able to use all the functions of this website to their full extent. </p>
                   
<p>Data processed in connection with the use of Google Analytics is automatically deleted after 26 Months</p>
          
<p>If and to the extent that the processing of data for the aforesaid purposes is made with your consent, the lega basis is Article 6 1 a) of the General Data Protection Regulation (Consent). Otherwise the data is processed on the legal basis of Artikel 6 1 f) of the General Data Probtection Regulation (“legitimate interests”), with the legitimate interests in question being the purposes mentioned above.
You can also download and install the browser plugin available at the following link to prevent the cookie-generated data that discloses your use of the website (including your IP address) from being collected and transmitted to Google and prevent Google from processing this data.
 	https://tools.google.com/dlpage/gaoptout?hl=en</p>
          
<h2>Rights of Data Subjects</h2>
          
<p>Under Article 15 General Data Protection Regulation, you have the right to obtain information on the processing of your personal data (“Right of access by the data subject”).</p>
          
<p>Under Article 16 General Data Protection Regulation, you have the right to rectification of incorrect personal data and deletion of personal data concerning you (“Right to rectification”).</p>
          
            <p>Under Article 17 General Data Protection Regulation, you can demand the deletion of personal data concerning you if one of the listed grounds applies (“Right to erasure/ Right to be forgotten”).</p>
          
<p>You also have the right, under Article 18 General Data Protection Regulation, to restrict the processing of personal data concerning you if one of the listed grounds applies (“Right to restriction of processing”).</p>
          
<p>Under Article 20 General Data Protection Regulation, you have the right to receive the personal data concerning you and to transmit that data to another controller (“Right to data portability”).</p>
          
<p>Revocation of consent: Refer to the section entitled “Right of Revocation” in this Privacy Policy. </p>
          
<p>Right of Revocation Refer to the section entitled “Right of Revocation” in this Privacy Policy. </p>
          
<p>You have the right to lodge a complaint with the relevant supervisory authority. The relevant supervisoryauthority is Die zuständige Aufsichtsbehörde ist Bayerische Landesbeauftragte für den Datenschutz (BayLfD), Prof. Dr. Thomas Petri, Postanschrift: Postfach 22 12 19, 80502 München, Hausanschrift: Wagmüllerstraße 18, 80538 München</p>
            
<h2>Right of Revocation</h2>
          
                <p>You can revoke your consent to the processing of your personal data, where such consent has been given, at any time, e.g. by way of email to our email address indicated first above. This does not affect the lawfulness of processing up to the time of consent revocation.</p>
          
            
          <h2>Right to Object</h2>

            <p>If we process data on the basis of Article 6, section 1 f) GDPR (“legitimate interests“), you are accorded the right under Article 21 GDPR to object to the processing of your personal data. </p>

            
<h2>Disclosure of Your Data</h2>

                <p>Where not already stated elsewhere in this Privacy Policy, we pass on your personal data to the following further recipients respectively recipient categories:</p>

                <ul>
                  <li>Payment service provider</li>
                  <li>Marketing service provider</li>

</ul>
            
                    
                              <p>Unless already stated above, we intend to pass on your personal data to the following third country or the following international organisations.</p>
                                <ul>
                <li>Google Ads/Analytics (USA)</li>
<li>Unity Analytics (Denmark / USA)</li>
</ul>
            
            
            

          
          
          
          
        </div>
                </React.Fragment>
              );
            }
          
}
