import React, { Component } from 'react';
import Unity, { UnityContext } from "react-unity-webgl";
import './Game.css';


class DebugGame extends Component {



    constructor(props) {
        super(props);
        this.loadingBar = React.createRef();
        this.progressBarFull = React.createRef();
        this.state = {
            token: "",
            version: "",
        }


        console.log("construtor" + this.props.version);




    }

    setupUnityComponents = (version) => {
        this.unityContext = new UnityContext({

            loaderUrl: "webglClient/SpookyHunters" + version + ".loader.js",
            dataUrl: "webglClient/SpookyHunters" + version + ".data",
            frameworkUrl: "webglClient/SpookyHunters" + version + ".framework.js",
            codeUrl: "webglClient/SpookyHunters" + version + ".wasm",
        });

        this.unityContext.on("GetSessionToken", () => {
            //this.setState({ degrees: Math.round(degrees) });
            this.unityContext.send("HTMLCalls", "SetToken", this.state.token);

        });

        this.loadingBar.current.style.display = "block";

        this.unityContext.on("progress", (percent) => {


            this.progressBarFull.current.style.width = 100 * percent + "%";

        });
        this.unityContext.on("loaded", () => {

            this.loadingBar.current.style.display = "none";


        });
    }

    componentDidMount = async () => {
        console.log("Will Mount");
        let version = "";
        var response = await fetch("api/version",
            {
                method: "get"
            });
        if (response.ok) {
            version = await response.text();
            this.setupUnityComponents(version);
            this.setState({ version: version });
            console.log(this.state.version);
        }
        else {
            console.error("Cannot get Versionnumber!");
        }


    }

    render() {

        return (<div className="unityContainer">
            {this.unityContext ? (<Unity unityContext={this.unityContext} width='100%' height='100vh' />) : ""}

            <div id="unity-loading-bar" ref={this.loadingBar}>
                <div id="unity-logo"></div>
                <div id="unity-progress-bar-empty">
                    <div id="unity-progress-bar-full" ref={this.progressBarFull}></div>
                </div>
            </div>
        </div >);
    }



}

export default DebugGame;