import React, { Component } from 'react';



export class Welcome extends Component {
  static displayName = Welcome.name;

  constructor(props) {
    super(props);
    this.state = {
      token: "",
    }

  }
  render() {

    return (
      <React.Fragment>
        <img src={require('./../assets/login/Login_screen_top.png')} className="loginTopImage" />
        <div className="container">

          <h1>Welcome (back) to all old and new agents of S.C.O.T.C.H.!</h1>

          <p>At the end of 2009 I started a post with the words “The time has finally come…”.</p>

          <p>More than a decade later I am writing the same thing again: “The time has finally come...”</p>


          <h2>What has happened?</h2>

          <p>In September 2019 I had a serious medical issue that almost cost me my life. After 9 weeks being hospitalized I came back to the company I founded, but which had been already sold in 2016 simply to keep things going. Now the company was about to be sold again by the new owners, not by me though, with many promises made to me. To make things short: The new owners didn’t live up to their promises and in the end I almost lost my life, my health, and now my company and the work of my life since they decided to shut GT down in December 2019.</p>

          <p>It was around Easter 2020´when I was tagged on Facebook and took a look at what was going on in the Hunters Lodge. It was the first month of the lockdowns and people said something like: “Guess we would trap a pandemic ghost today”.
          So I asked if there still was interest in GT and many surprisingly  said yes.
But there I was alone with no resources. Lucky me I still know some people and was phoning around until I found two others who were very interested in reviving GT - or a similar game - because they want to have something which would be their own. So “Spooky Hunters” was born</p>

          <p>So we started a Patreon. I cannot emphasize how grateful we are to our Patreons: To be honest: All this wouldn't have been possible without them! They will get some perks, but it is well deserved!</p>

          <p>Thanks to some fortunate events we even got the name back and could now rename “Spooky Hunters” to “Ghost Trappers Classic”. Why Classic? Because it is GT, but with a new beginning, New things but a lot of familiar stuff and faces … and ghosts!</p>


          <h2>Will I get my old items and progress back?</h2>

          <p>Unfortunately no. The database has been completely deleted and we are a totally new company now. You have to start all over as if it was 10 years ago, but this time the game will be more modern. Looking back on a decade of trapping ghosts I think I have a good idea what went wrong, what can be improved, and what can be kept.</p>

          <h2>But why has GT been shut down in the first place?</h2>

          <p>Well, short answer: money and no real interest in the game by the new owners.
Long answer: After 10 years you have to add new things and there is no way around the game getting bigger, bloated, more complex. In the end players felt it was not casual enough, quite complicated, and they felt lost. Pair that with the now very bad Facebook algorithm that makes a game invisible compared to 2010, and the playerbase simply moving on.</p>

          <h2>So what will be different this time?</h2>

          <p>Quite a few things:
            <ul>
              <li>The game overall will be slower and more casual.</li>
              <li>We aim to not have a hunting CD lower than 8 minutes.</li>
              <li>Nessy’s will be scarce and has to be the best bait in the game. Period. In the end players had literally millions of Nessy’s.</li>
              <li>Mystic power must mean something and should never go over 25%.</li>
              <li>We don’t want to rely on a Wiki and try to give you all the necessary information in the game itself.</li>
              <li>Some mechanics like trap doors will be gone. Instead every Region has a special fifth Hunting Location with its own theme, e.g. the Raven Runs in Region 1.</li>
Special items are very powerful and should also be very scarce.</ul></p>


          <h2>The elephant in the room … the Premium Shop</h2>

          <p>Like every business in the world the game has to be financially viable. This is no exception. Our goal is not to become rich. I know, doesn’t sound believable, but I value running my own game more than money. But there is a minimum we need.
          As you can see we have a subscription model now. Not exclusively, but you can subscribe to GTC if you like. Actually, some people in the Hunters Lodge suggested this. I know that subscriptions always leave a bad taste in the mouth, but for us it is so much easier to plan things ahead. See it this way please: If you choose to become a subscriber, you do it to support and keep the game alive, not for the ingame benefits you will get.
Of course all items from the shop will be available as single deals, and the shop will be much more comprehensive. To be honest, the old one was a disaster :D</p>

          <h2>So what if I can’t or don’t want to donate?</h2>

          <p>That is perfectly fine, we are well aware of the financial situation the world is in right now. You can still help by letting all your old GT friends know that we are back, recruit new players, and be active on Discord. In case you read this somewhere else than Discord, please join. It really is easy to use and it has become the number one communication platform for games. It is like Skype, chat rooms, voice and video chats, forums all rolled into one. All bigger posts and announcements will happen there.
<a href="https://discord.gg/UF3pYTR">https://discord.gg/UF3pYTR</a></p>


          <h2>On which devices will GTC be available.?</h2>
          <p>We want to get away from Facebook. This time the game is coded in Unity3D as opposed to the now ancient PHP from the original. That means a conversion to IOS and Android is “relatively” easy. This is where we want to go next. You will have one login, so it won’t matter from where you log in, smartphone, tablets, or PC.</p>






          <h2>What will come next?</h2>

          <p>The first big update will be Region 2 “Borders” with the return of the Special Missions. This time we know what players liked and did not like so much about the Missions: Distribution, rewards according to progress, and not “all or nothing”. We want to have a special currency only for Missions, so you can decide which items you want to buy or save for.
Later of course we want to bring back favourites like the Chrono Crystal, Monsters, and Cameras for secret ghosts. But this may take a while.</p>

          <p>And lastly I want to thank Nancy (Sultana) and Terri (Errorghost) for all the work they have put into GTC, and the Hunters Lodge on Facebook and its members for staying around even when times looked desperate.</p>


          <p>So all we can do now is to hope for at least another 10 years of ghost trapping!</p>


Andi - once again the Chairman of S.C.O.T.C.H.!











        </div>
        <div className="playNowContainer">
          <a href="/game">PLAY NOW</a>
        </div>
      </React.Fragment>
    );
  }

}
