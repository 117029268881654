import React, { Component } from 'react';



export class ActivateAccount extends Component {
    // static displayName = Shopcallback.name;
    constructor(props) {
        super(props);
        this.state =
        {
            response: "loading...",
        }
    }

    componentWillMount = async () => {

        // this.checkOnItems();
        var response = await fetch("api/user/ActivateAccount/" + this.props.match.params.id,
            {
                method: "get",
                headers: {
                    ApiKey: process.env.REACT_APP_API_KEY
                }
            });
        if (response.ok) {
            var resp = await response.text();
            this.setState({ response: resp });

        }
        else {

            this.setState({ response: "something went wrong" });
        }
    }

    renderContent = () => {
        return (<React.Fragment>
            <h1>{this.state.response}</h1><div className="playNowContainer">
                <a href="/game">Play Now!</a>
            </div>

        </React.Fragment>);
    }

    render() {

        return (
            <React.Fragment>

                <img src={require('./../assets/login/Login_screen_top.png')} className="loginTopImageSmall" />
                {this.renderContent()}

            </React.Fragment>




        );
    }

}
