import React, { Component } from 'react';



export class ForgotPasswordRetrieve extends Component {
    // static displayName = Shopcallback.name;
    constructor(props) {
        super(props);
        this.state =
        {
            response: "loading...",

        }
    }

    // checkOnItems = async () => {
    //     console.log("DID call checkOnItems");
    //     let formData = new FormData();
    //     formData.append('paymentId', this.props.match.params.pid);

    //     var json = { completed: false };

    //     while (!this.state.complete && !this.state.error) {
    //         var response = await fetch("api/payment/CheckOnPayment",
    //             {
    //                 body: formData,
    //                 method: "post"
    //             });
    //         if (response.ok) {
    //             var json = await response.json();
    //             setTimeout(1000);
    //             console.log(json.completed);
    //             this.setState({ complete: json.completed })
    //         }
    //         else {
    //             this.setState({ error: true })
    //             return;
    //         }
    //     }
    // }

    componentWillMount = async () => {

        // this.checkOnItems();
        var response = await fetch("api/user/ForgotPasswordRetrieve/" + this.props.match.params.id,
            {
                method: "get",
                headers: {
                    ApiKey: process.env.REACT_APP_API_KEY
                }
            });
        if (response.ok) {
            var resp = await response.text();
            this.setState({ response: resp });

        }
        else {

            this.setState({ response: "something went wrong" });
        }
    }

    renderContent = () => {
        return (<React.Fragment>
            <p>{this.state.response}</p>
        </React.Fragment>);
    }

    render() {

        return (
            <React.Fragment>

                <img src={require('./../assets/login/Login_screen_top.png')} className="loginTopImageSmall" />
                {this.renderContent()}

            </React.Fragment>




        );
    }

}
