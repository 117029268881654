import React, { Component } from 'react';
import { Route, Switch } from 'react-router';


import './App.css'
import Game from './components/Game';
import DebugGame from './components/DebugGame';
import AppPages from './AppPages';
import AdminPanel from './components/AdminPanel';

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    return (
      <div className="gtpage">
        <Switch>
          <Route path='/game' component={Game} />
          <Route path='/debuggame' component={DebugGame} />
          <Route path='/adminPanel' component={AdminPanel} />
          <Route path='/' component={AppPages} />
        </Switch>
      </div>
    );
  }
}
