import React, { Component } from 'react';
import './Login.css';

class Login extends Component {


    constructor(props) {
        super(props);
        this.state = {
            token: "",
        }

    }
    render() {

        return (<div className="gtpage">
            <img src={require('./../assets/login/Login_screen_top.png')} className="loginTopImage" />
            <div className="loginContainer">
                <div className="frameBack">
                    <div className="frame">
                        <div className="content">
                            <form>
                                <input type="text" name="username" placeholder="Username" className="m-2" /><br />
                                <input type="password" name="password" placeholder="Password" className="m-2" /><br />

                                <input type="submit" value="Submit" className="m-2" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>





        </div >);
    }



}

export default Login;