// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import CookieConsent from "react-cookie-consent";
import './Index.css';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      enableDeclineButton
      declineButtonText="Decline"
      cookieName="gtc_cookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
      expires={150}
    >
      This site or third-party tools used by this site make use of cookies necessary for the operation and aditionally for statistical and marketing purposes. You can decline the non necessary use of cookies.
    </CookieConsent>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

