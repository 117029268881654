import React, { Component } from 'react';
import './Home.css';


export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.state = {
      token: "",
    }

  }
  render() {

    return (

      <React.Fragment>

        <img src={require('./../assets/login/Login_screen_top.png')} className="loginTopImage" />
        <div className="playNowContainer">
          <a href="/game">PLAY NOW</a>
        </div>


      </React.Fragment>




    );
  }

}
